import React from 'react';
import { Button, ButtonProps, useTheme } from '@chakra-ui/react';
import tinycolor from 'tinycolor2';
import styles from './DynamicButton.module.scss';

interface DynamicButtonProps extends ButtonProps {
  backgroundColor?: string;
}

const DynamicButton: React.FC<DynamicButtonProps> = ({ backgroundColor, children, ...props }) => {
  const theme = useTheme();

  const bgColor = backgroundColor || theme.colors.light.primary;
  const textColor = tinycolor(bgColor).getBrightness() > 128
    ? 'hsl(210, 19%, 8%)'
    : 'hsl(217, 41%, 90%)';

  const boxShadowColor = tinycolor(bgColor).darken(15).setAlpha(0.5).toString();
  const boxShadowHoverColor = tinycolor(bgColor).darken(10).setAlpha(0.5).toString();
  const boxShadowActiveColor = tinycolor(bgColor).darken(25).setAlpha(0.6).toString();

  return (
    <Button
      {...props}
      className={styles.dynamicButton}
      bg={bgColor}
      color={textColor}
      fontFamily={theme.fonts.body}
      fontWeight="normal"
      fontSize="lg"
      boxShadow={`inset 0 0 25px ${boxShadowColor}, 0 0 2px 0 ${boxShadowColor}`}
      transition="all 0.3s ease"
      _hover={{
        boxShadow: `inset 0 0 30px ${boxShadowHoverColor}, 0 0 2px 0 ${boxShadowHoverColor}, 0 0 12px ${boxShadowHoverColor}`,
      }}
      _active={{
        bg: tinycolor(bgColor).darken(5).toString(),
        boxShadow: `inset 0 0 60px ${boxShadowActiveColor}, 0 0 12px ${boxShadowActiveColor}`,
        color: tinycolor(textColor).setAlpha(0.7).toString(),
      }}
      _disabled={{
        opacity: 0.6,
        cursor: 'not-allowed',
        boxShadow: 'none',
      }}
    >
      {children}
    </Button>
  );
};

export default DynamicButton;

import ReactDOM from 'react-dom/client';
import { ColorModeScript } from '@chakra-ui/react';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import createTheme from './theme';
import { createConfig } from './config/appConfig';
import './styles/main.scss';

const config = createConfig();
const theme = createTheme(config);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <>
    {/* <React.StrictMode> */}
    <ColorModeScript initialColorMode={theme.config.initialColorMode} />
    <BrowserRouter>
      <App />
    </BrowserRouter>
    {/* </React.StrictMode> */}
  </>
);

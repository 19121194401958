import customConfig from './customConfig';

export interface ThemeColors {
  backgroundColor: string;
  primaryColor: string;
  secondaryColor: string;
  textColor: string;
  borderColor: string;
  shadowColor: string;
  headingTextColor: string;
  bodyTextColor: string;
}

export interface AppConfig {
  applicationName: string;
  logoText: string;
  logoFontColor: string;
  logoFont: string;
  selectedLanguageCode: string;
  headingFont: string;
  bodyFont: string;
  copyrightText: string;
  apiBaseUrl: string;
  light: ThemeColors;
  dark: ThemeColors;
}

export const defaultConfig: AppConfig = {
  applicationName: 'My Application',
  logoText: 'LOGO',
  logoFontColor: 'hsl(210, 15%, 85%)',
  logoFont: '"Urbanist", sans-serif',
  selectedLanguageCode: 'en',
  headingFont: '"Urbanist", sans-serif',
  bodyFont: '"Urbanist", sans-serif',
  copyrightText: '© 2024 My Company',
  apiBaseUrl: 'https://api.example.com',
  light: {
    backgroundColor: 'hsl(216, 40%, 90%)',
    primaryColor: 'hsl(3, 70%, 50%)',
    secondaryColor: 'hsl(3, 60%, 60%)',
    textColor: 'hsl(210, 20%, 25%)',
    borderColor: 'hsl(210, 18%, 87%)',
    shadowColor: 'hsla(210, 20%, 25%, 0.1)',
    headingTextColor: 'hsl(3, 70%, 30%)',
    bodyTextColor: 'hsl(210, 20%, 25%)',
  },
  dark: {
    backgroundColor: 'hsl(210, 30%, 15%)',
    primaryColor: 'hsl(3, 70%, 50%)',
    secondaryColor: 'hsl(3, 60%, 60%)',
    textColor: 'hsl(210, 15%, 85%)',
    borderColor: 'hsl(210, 15%, 30%)',
    shadowColor: 'hsla(0, 0%, 0%, 0.2)',
    headingTextColor: 'hsl(3, 70%, 70%)',
    bodyTextColor: 'hsl(210, 15%, 85%)',
  },
};

export const createConfig = (): AppConfig => {
  return {
    ...defaultConfig,
    ...customConfig,
    light: { ...defaultConfig.light, ...(customConfig.light || {}) },
    dark: { ...defaultConfig.dark, ...(customConfig.dark || {}) },
  };
};

export default createConfig;

import React, { useState, useRef, useCallback, useMemo } from 'react';
import { Box, VStack, FormControl, FormLabel, Input, Text, useToast, Progress, Center, FormErrorMessage } from '@chakra-ui/react';
import { FiUpload, FiFile } from 'react-icons/fi';
import { createTask } from '../../api';
import styles from './TaskCreationForm.module.scss';
import DynamicButton from '../shared/DynamicButton';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../shared/ErrorFallback';

interface TaskCreationFormProps {
  onTaskCreated: (taskId: string) => void;
}

const TaskCreationForm: React.FC<TaskCreationFormProps> = React.memo(({ onTaskCreated }) => {
  const [taskName, setTaskName] = useState('');
  const [audioFile, setAudioFile] = useState<File | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [formErrors, setFormErrors] = useState<{ taskName?: string; audioFile?: string }>({});
  const fileInputRef = useRef<HTMLInputElement>(null);
  const toast = useToast();

  const validAudioFormats = useMemo(() => [
    'audio/mpeg', 'audio/mp3', 'audio/wav', 'audio/ogg', 'audio/aac', 'audio/flac'
  ], []);

  const handleTaskNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setTaskName(e.target.value);
    setFormErrors(prev => ({ ...prev, taskName: undefined }));
  }, []);

  const validateAndSetFile = useCallback((file: File | undefined) => {
    if (file && validAudioFormats.includes(file.type)) {
      setAudioFile(file);
      setFormErrors(prev => ({ ...prev, audioFile: undefined }));
      toast({
        title: 'Archivo seleccionado',
        description: `${file.name} ha sido seleccionado.`,
        status: 'success',
        duration: 3000,
        isClosable: true,
        containerStyle: {
          marginBottom: '70px',
        },
      });
    } else {
      setFormErrors(prev => ({ ...prev, audioFile: 'Por favor, selecciona un archivo de audio válido (MP3, WAV, OGG, AAC, FLAC).' }));
      toast({
        title: 'Formato de archivo inválido',
        description: 'Por favor, selecciona un archivo de audio válido (MP3, WAV, OGG, AAC, FLAC).',
        status: 'error',
        duration: 3000,
        isClosable: true,
        containerStyle: {
          marginBottom: '70px',
        },
      });
    }
  }, [validAudioFormats, toast]);

  const handleFileSelect = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    validateAndSetFile(file);
  }, [validateAndSetFile]);

  const handleDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  }, []);

  const handleDrop = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    validateAndSetFile(file);
  }, [validateAndSetFile]);

  const handleSubmit = useCallback(async (e: React.FormEvent) => {
    e.preventDefault();
    const errors: { taskName?: string; audioFile?: string } = {};
    if (!taskName.trim()) {
      errors.taskName = 'Por favor, proporciona un nombre de tarea.';
    }
    if (!audioFile) {
      errors.audioFile = 'Por favor, selecciona un archivo de audio.';
    }
    setFormErrors(errors);

    if (Object.keys(errors).length > 0) {
      return;
    }

    setIsSubmitting(true);
    try {
      const taskId = await createTask(taskName, audioFile!, (progress) => {
        setUploadProgress(progress);
      });
      toast({
        title: 'Tarea Creada',
        description: `La tarea ${taskId} ha sido creada exitosamente.`,
        status: 'success',
        duration: 5000,
        isClosable: true,
        containerStyle: {
          marginBottom: '70px',
        },
      });
      onTaskCreated(taskId);
      setTaskName('');
      setAudioFile(null);
      setUploadProgress(0);
    } catch (error) {
      console.error('Error creating task:', error);
      toast({
        title: 'Error',
        description: 'Error al crear la tarea. Por favor, intenta de nuevo.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        containerStyle: {
          marginBottom: '70px',
        },
      });
    } finally {
      setIsSubmitting(false);
    }
  }, [taskName, audioFile, toast, onTaskCreated]);

  const dropzoneContent = useMemo(() => {
    if (isSubmitting) {
      return (
        <Center flexDirection="column" height="100%">
          <Text fontSize="lg" fontWeight="bold" mb={4}>
            Subiendo... {uploadProgress}%
          </Text>
          <Progress
            value={uploadProgress}
            size="lg"
            width="80%"
            colorScheme="blue"
            borderRadius="md"
          />
        </Center>
      );
    }
    if (audioFile) {
      return (
        <>
          <FiFile size={48} className={styles.fileIcon} />
          <Text mt={2} fontWeight="bold">{audioFile.name}</Text>
          <Text mt={1}>Haz clic o arrastra un nuevo archivo para cambiar</Text>
        </>
      );
    }
    return (
      <>
        <FiUpload size={48} />
        <Text mt={2}>Arrastra y suelta un archivo de audio aquí o haz clic para seleccionar</Text>
      </>
    );
  }, [isSubmitting, uploadProgress, audioFile]);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Box className={styles.taskCreationForm}>
        <form onSubmit={handleSubmit}>
          <VStack spacing={4} align="stretch">
            <FormControl isInvalid={!!formErrors.taskName}>
              <FormLabel htmlFor="taskName">Nombre de la Tarea</FormLabel>
              <Input
                id="taskName"
                type="text"
                value={taskName}
                onChange={handleTaskNameChange}
                placeholder="Ingresa el nombre de la tarea"
                disabled={isSubmitting}
                maxLength={100}
              />
              <FormErrorMessage>{formErrors.taskName}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!formErrors.audioFile}>
              <FormLabel htmlFor="audioFile">Archivo de Audio</FormLabel>
              <Box
                className={`${styles.dropZone} ${audioFile ? styles.fileSelected : ''} ${isSubmitting ? styles.uploading : ''}`}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                onClick={() => fileInputRef.current?.click()}
              >
                {dropzoneContent}
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleFileSelect}
                  accept="audio/*"
                  style={{ display: 'none' }}
                  disabled={isSubmitting}
                />
              </Box>
              <FormErrorMessage>{formErrors.audioFile}</FormErrorMessage>
            </FormControl>
            <DynamicButton
              type="submit"
              isDisabled={!taskName || !audioFile || isSubmitting}
              isLoading={isSubmitting}
              loadingText="Creando Tarea..."
            >
              Crear Tarea
            </DynamicButton>
          </VStack>
        </form>
      </Box>
    </ErrorBoundary>
  );
});

export default TaskCreationForm;

// src/pages/HomePage.tsx
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './HomePage.module.scss';
import DynamicButton from '../components/shared/DynamicButton';
import DynamicLogo from '../components/shared/DynamicLogo';
import CopyrightInfo from '../components/shared/CopyrightInfo';
import { useTheme } from '@chakra-ui/react';

import backgroundImage from '../assets/images/slides/capital_background.webp';

const HomePage: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleIngresarClick = useCallback(() => {
    navigate('/login');
  }, [navigate]);

  return (
    <div className={styles.homepageWrapper}>
      <div className={styles.homepageContainer}>
        <div className={styles.headerContainer}>
          <div className={styles.logoContainer}>
            <DynamicLogo logoColor={theme.colors.light.primary} />
          </div>
          <div className={styles.welcomeMessage}>
            <p>Procesos automatizados con inteligencia artificial.</p>
            <p>Genera y gestiona artículos a partir de audios de programas de radio.</p>
          </div>
        </div>
        <div className={styles.imageWrapper}>
          <div className={styles.imageContainer}>
            <img
              src={backgroundImage}
              alt="Capital AI Background"
              className={styles.backgroundImage}
              loading="lazy"
            />
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <DynamicButton onClick={handleIngresarClick}>
            Ingresar
          </DynamicButton>
        </div>
        <div className={styles.copyrightInfoContainer}>
          <CopyrightInfo />
        </div>
      </div>
    </div>
  );
};

export default React.memo(HomePage);

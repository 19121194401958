import axios, { AxiosError } from 'axios';

const API_URL = import.meta.env.VITE_API_URL || 'http://localhost:5000';

console.log('API URL:', API_URL);

const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

api.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    console.error('Response error:', error.response ? error.response.data : error.message);
    return Promise.reject(error);
  }
);

const handleApiError = (error: unknown, defaultMessage: string): Error => {
  if (axios.isAxiosError(error)) {
    const message = error.response?.data?.message || error.message || defaultMessage;
    return new Error(message);
  }
  return new Error(defaultMessage);
};

export const login = async (username: string, password: string): Promise<void> => {
  try {
    const response = await api.post('/auth/login', { username, password });
    return response.data;
  } catch (error) {
    throw handleApiError(error, 'Error de inicio de sesión');
  }
};

export const logout = async (): Promise<void> => {
  try {
    await api.post('/auth/logout');
  } catch (error) {
    throw handleApiError(error, 'Error al cerrar sesión');
  }
};

export const checkAuthStatus = async (): Promise<boolean> => {
  try {
    const response = await api.get('/auth/check-auth');
    return response.data.isAuthenticated;
  } catch (error) {
    console.error('Error checking auth status:', error);
    return false;
  }
};

export const createTask = async (taskName: string, audioFile: File, onProgress?: (progress: number) => void): Promise<string> => {
  const formData = new FormData();
  formData.append('task_name', taskName);
  formData.append('file', audioFile);

  try {
    const response = await api.post('/tasks', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent) => {
        if (progressEvent.total) {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          onProgress?.(percentCompleted);
        }
      },
    });

    if (response.status !== 201) {
      throw new Error('Task creation failed');
    }

    return response.data.task_id;
  } catch (error) {
    throw handleApiError(error, 'Error al crear la tarea');
  }
};

export const fetchTasks = async (): Promise<any[]> => {
  try {
    const response = await api.get('/tasks');
    return response.data;
  } catch (error) {
    throw handleApiError(error, 'Error al obtener las tareas');
  }
};

export const fetchTaskDetails = async (taskId: string): Promise<any> => {
  try {
    const response = await api.get(`/tasks/${taskId}`);
    return response.data;
  } catch (error) {
    throw handleApiError(error, 'Error al obtener los detalles de la tarea');
  }
};

export const fetchTaskArticles = async (taskId: string): Promise<any> => {
  try {
    const response = await api.get(`/tasks/${taskId}/articles`);
    return response.data;
  } catch (error) {
    throw handleApiError(error, 'Error al obtener los artículos de la tarea');
  }
};

export const fetchTaskSnippets = async (taskId: string): Promise<any> => {
  try {
    const response = await api.get(`/tasks/${taskId}/snippets`);
    return response.data;
  } catch (error) {
    throw handleApiError(error, 'Error al obtener los extractos de la tarea');
  }
};

export default api;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Header.module.scss';
import { ThemeToggleButton } from './ThemeToggleButton';
import DynamicLogo from './DynamicLogo';
import DynamicButton from './DynamicButton';
import { useAuthStore } from '../../store/authStore';
import { logout } from '../../api';

export const Header: React.FC = () => {
  const navigate = useNavigate();
  const { setIsAuthenticated } = useAuthStore();

  const handleLogout = async () => {
    try {
      await logout();
      setIsAuthenticated(false);
      navigate('/');
    } catch (error) {
      console.error('Logout failed:', error);
      // TODO: Add error handling, e.g., show a toast notification
    }
  };

  return (
    <header className={styles.header}>
      <div className={styles.logoWrapper}>
        <DynamicLogo />
      </div>
      <div className={styles.rightSection}>
        <DynamicButton onClick={handleLogout}>Cerrar Sesión</DynamicButton>
        <ThemeToggleButton />
      </div>
    </header>
  );
};

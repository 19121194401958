import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Box, VStack, Text, useToast } from '@chakra-ui/react';
import { fetchTaskDetails } from '../../api';
import styles from './TaskDetails.module.scss';
import DynamicButton from '../shared/DynamicButton';
import LoadingOverlay from '../shared/LoadingOverlay';
import ErrorBoundary from '../shared/ErrorBoundary';

interface TaskDetails {
  id: string;
  name: string;
  status: string;
  created_at: string;
  updated_at: string;
  files: {
    transcription_file?: string;
    article_files?: string[];
  };
  articles_generated: boolean;
  snippets_generated: boolean;
  is_auto_created: boolean;
  steps: {
    [key: string]: {
      status: string;
      completed_at: string | null;
    };
  };
}

interface TaskDetailsProps {
  taskId: string;
  taskStatus: string;
  onViewArticles: () => void;
  onViewSnippets: () => void;
  onTaskUpdated: () => void;
}

const TaskDetails: React.FC<TaskDetailsProps> = React.memo(({
  taskId,
  taskStatus,
  onViewArticles,
  onViewSnippets,
  onTaskUpdated
}) => {
  const [taskDetails, setTaskDetails] = useState<TaskDetails | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const toast = useToast();

  const loadTaskDetails = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const details = await fetchTaskDetails(taskId);
      setTaskDetails(details);
    } catch (error) {
      console.error('Error fetching task details:', error);
      setError('No se pudieron cargar los detalles de la tarea. Por favor, intente de nuevo.');
      toast({
        title: 'Error',
        description: 'No se pudieron cargar los detalles de la tarea. Por favor, intente de nuevo.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        containerStyle: {
          marginBottom: '70px',
        },
      });
    } finally {
      setIsLoading(false);
    }
  }, [taskId, toast]);

  useEffect(() => {
    loadTaskDetails();
  }, [loadTaskDetails]);

  useEffect(() => {
    if (taskDetails && taskDetails.status !== taskStatus) {
      loadTaskDetails();
      onTaskUpdated();
    }
  }, [taskStatus, taskDetails, loadTaskDetails, onTaskUpdated]);

  const formatDate = useCallback((dateString: string) => {
    const date = new Date(dateString);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    if (date.toDateString() === today.toDateString()) {
      return `Hoy a las ${date.toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit' })}`;
    } else if (date.toDateString() === yesterday.toDateString()) {
      return `Ayer a las ${date.toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit' })}`;
    } else {
      return date.toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' });
    }
  }, []);

  const getStatusText = useCallback((status: string) => {
    switch (status.toLowerCase()) {
      case 'completed':
        return 'Completado';
      case 'pending':
        return 'Pendiente';
      case 'error':
        return 'Error';
      default:
        return status;
    }
  }, []);

  const addPeriodIfNeeded = useCallback((text: string) => {
    return text.endsWith('.') ? text : `${text}.`;
  }, []);

  const memoizedContent = useMemo(() => {
    if (!taskDetails) return null;

    return (
      <VStack align="stretch" spacing={4} className={styles.taskDetails}>
        <Text fontSize="xl" fontWeight="bold">
          {taskDetails.name}
        </Text>
        <Text>
          <strong>Estado:</strong> <span className={styles[taskDetails.status.toLowerCase()]}>{addPeriodIfNeeded(getStatusText(taskDetails.status))}</span>
        </Text>
        <Text>
          <strong>Creado:</strong> {addPeriodIfNeeded(formatDate(taskDetails.created_at))}
        </Text>
        <Text>
          <strong>Actualizado:</strong> {addPeriodIfNeeded(formatDate(taskDetails.updated_at))}
        </Text>
        {taskDetails.is_auto_created && (
          <Text>
            <strong>Fuente:</strong> Procesado automáticamente a partir de RSS.
          </Text>
        )}

        {taskDetails.articles_generated && (
          <DynamicButton onClick={onViewArticles}>
            Ver artículos generados
          </DynamicButton>
        )}
        {taskDetails.snippets_generated && (
          <DynamicButton onClick={onViewSnippets}>
            Ver extractos generados
          </DynamicButton>
        )}
      </VStack>
    );
  }, [taskDetails, formatDate, getStatusText, addPeriodIfNeeded, onViewArticles, onViewSnippets]);

  if (isLoading) {
    return <LoadingOverlay message="Cargando detalles de la tarea..." />;
  }

  if (error) {
    return (
      <Box className={styles.taskDetailsContainer}>
        <Box className={styles.messageContainer}>
          <Text className={styles.errorMessage}>{error}</Text>
          <DynamicButton onClick={loadTaskDetails}>Reintentar</DynamicButton>
        </Box>
      </Box>
    );
  }

  if (!taskDetails) {
    return (
      <Box className={styles.taskDetailsContainer}>
        <Box className={styles.messageContainer}>
          <Text className={styles.infoMessage}>No se encontraron detalles para esta tarea.</Text>
        </Box>
      </Box>
    );
  }

  return (
    <ErrorBoundary>
      <Box className={styles.taskDetailsContainer}>
        {memoizedContent}
      </Box>
    </ErrorBoundary>
  );
});

export default TaskDetails;

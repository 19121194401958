import React, { useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Box, VStack, FormControl, FormLabel, Input, FormErrorMessage, useToast } from '@chakra-ui/react';
import DynamicButton from '../components/shared/DynamicButton';
import DynamicLogo from '../components/shared/DynamicLogo';
import styles from './LoginPage.module.scss';
import { useTheme } from '@chakra-ui/react';
import { login } from '../api';
import { useAuthStore } from '../store/authStore';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../components/shared/ErrorFallback';

interface LoginFormInputs {
  username: string;
  password: string;
}

const LoginPage: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { setIsAuthenticated } = useAuthStore();
  const { register, handleSubmit, formState: { errors } } = useForm<LoginFormInputs>();
  const toast = useToast();

  const onSubmit = useCallback(async (data: LoginFormInputs) => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    try {
      await login(data.username, data.password);
      setIsAuthenticated(true);
      navigate('/dashboard');
    } catch (error) {
      console.error('Error during login:', error);
      toast({
        title: 'Error de inicio de sesión',
        description: error instanceof Error ? error.message : 'Ha ocurrido un error. Por favor, intente de nuevo.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        containerStyle: {
          marginBottom: '70px',
        },
      });
    } finally {
      setIsSubmitting(false);
    }
  }, [isSubmitting, setIsAuthenticated, navigate, toast]);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Box className={styles.loginPage}>
        <Box className={styles.loginContainer}>
          <Box className={styles.logoContainer}>
            <DynamicLogo logoColor={theme.colors.light.primary} />
          </Box>
          <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
            <VStack spacing={4} align="stretch">
              <FormControl isInvalid={!!errors.username}>
                <FormLabel htmlFor="username">Usuario</FormLabel>
                <Input
                  id="username"
                  {...register('username', {
                    required: 'El nombre de usuario es obligatorio',
                  })}
                  disabled={isSubmitting}
                />
                <FormErrorMessage>
                  {errors.username && errors.username.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!errors.password}>
                <FormLabel htmlFor="password">Contraseña</FormLabel>
                <Input
                  id="password"
                  type="password"
                  {...register('password', {
                    required: 'La contraseña es obligatoria',
                  })}
                  disabled={isSubmitting}
                />
                <FormErrorMessage>
                  {errors.password && errors.password.message}
                </FormErrorMessage>
              </FormControl>
              <DynamicButton type="submit" isLoading={isSubmitting} disabled={isSubmitting}>
                {isSubmitting ? 'Iniciando sesión...' : 'Iniciar Sesión'}
              </DynamicButton>
            </VStack>
          </form>
        </Box>
      </Box>
    </ErrorBoundary>
  );
};

export default React.memo(LoginPage);

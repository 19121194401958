import React from 'react';
import { Box, VStack, Text, Image } from '@chakra-ui/react';
import styles from './LoadingOverlay.module.scss';
import CustomProgressBar from './CustomProgressBar';
import loadingSvgUrl from '../../assets/svg/puff.svg';

interface LoadingOverlayProps {
  message: string;
  showProgressBar?: boolean;
  progressBarType?: 'indeterminate' | 'determinate';
  progress?: number;
  progressText?: string;
}

const LoadingOverlay: React.FC<LoadingOverlayProps> = React.memo(({
  message,
  showProgressBar = false,
  progressBarType = 'indeterminate',
  progress,
  progressText,
}) => {
  return (
    <Box className={styles.loadingOverlay}>
      <VStack spacing={6} className={styles.loadingInner}>
        <Image src={loadingSvgUrl} alt="Loading" className={styles.loadingImgContainer} />
        <Text className={styles.loadingText}>{message}</Text>
        {showProgressBar && (
          <Box className={styles.progressBarContainer}>
            <CustomProgressBar
              indeterminate={progressBarType === 'indeterminate'}
              progress={progress || 0}
              progressText={progressText}
            />
          </Box>
        )}
      </VStack>
    </Box>
  );
});

export default LoadingOverlay;

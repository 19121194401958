import React, { useEffect, useRef, useCallback } from 'react';
import { useConfig } from '../../hooks/useConfig';
import styles from './DynamicLogo.module.scss';

interface DynamicLogoProps {
  logoColor?: string;
}

const DynamicLogo: React.FC<DynamicLogoProps> = ({ logoColor }) => {
  const config = useConfig();
  const containerRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLSpanElement>(null);

  const finalLogoColor = logoColor || config.logoFontColor;

  const resizeLogo = useCallback(() => {
    if (containerRef.current && textRef.current) {
      const container = containerRef.current;
      const text = textRef.current;

      text.style.transform = 'scale(1)';

      const containerWidth = container.clientWidth;
      const containerHeight = container.clientHeight;
      const textWidth = text.offsetWidth;
      const textHeight = text.offsetHeight;

      const scaleX = containerWidth / textWidth;
      const scaleY = containerHeight / textHeight;
      const scale = Math.min(scaleX, scaleY);

      text.style.transform = `scale(${scale})`;
    }
  }, []);

  useEffect(() => {
    resizeLogo();

    const resizeObserver = new ResizeObserver(resizeLogo);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [resizeLogo, config.logoText]);

  return (
    <div
      ref={containerRef}
      className={styles.logoContainer}
      style={{
        fontFamily: config.logoFont,
        color: finalLogoColor,
      }}
    >
      <span ref={textRef} className={styles.logoText}>{config.logoText}</span>
    </div>
  );
};

export default DynamicLogo;

import { create } from 'zustand';
import { AppConfig } from '../config/appConfig';

interface ConfigStore {
  config: Partial<AppConfig>;
  setConfig: (config: Partial<AppConfig>) => void;
}

export const useConfigStore = create<ConfigStore>((set) => ({
  config: {},
  setConfig: (newConfig) => set((state) => ({
    config: { ...state.config, ...newConfig }
  })),
}));

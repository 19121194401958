import React from 'react';
import styles from './CopyrightInfo.module.scss';

const CopyrightInfo: React.FC = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className={styles.copyrightContainer}>
      <p>{currentYear} Capital Radio Economía SL</p>
      {/* <p>Subvencionado por ...</p> */}
      <p>
        Elaborado por{' '}
        <a href="https://www.unuware.com/index.html" className={styles.link}>
          UNUWARE SL
        </a>
      </p>
    </div>
  );
};

export default CopyrightInfo;

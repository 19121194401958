import { AppConfig } from './appConfig';

const customConfig: Partial<AppConfig> = {
  applicationName: "Capital AI",
  copyrightText: "© 2024 Capital Radio",
  apiBaseUrl: "https://api.capitalradio.com",

  selectedLanguageCode: "es",

  logoText: "Capital AI",
  logoFontColor: "hsl(210, 15%, 85%)",
  logoFont: '"Urbanist", sans-serif',

  headingFont: '"Urbanist", sans-serif',
  bodyFont: '"Urbanist", sans-serif',

  light: {
    backgroundColor: 'hsl(216, 40%, 90%)',
    primaryColor: 'hsl(360, 60%, 37%)',
    secondaryColor: 'hsl(3, 60%, 60%)',
    textColor: 'hsl(210, 20%, 25%)',
    borderColor: 'hsl(210, 18%, 87%)',
    shadowColor: 'hsla(210, 20%, 25%, 0.1)',
    headingTextColor: 'hsl(3, 70%, 30%)',
    bodyTextColor: 'hsl(210, 20%, 25%)',
  },
  dark: {
    backgroundColor: 'hsl(210, 30%, 15%)',
    primaryColor: 'hsl(360, 60%, 38%)',
    secondaryColor: 'hsl(3, 60%, 60%)',
    textColor: 'hsl(210, 15%, 85%)',
    borderColor: 'hsl(210, 15%, 30%)',
    shadowColor: 'hsla(0, 0%, 0%, 0.2)',
    headingTextColor: 'hsl(3, 70%, 70%)',
    bodyTextColor: 'hsl(210, 15%, 85%)',
  },
};

export default customConfig;

import { extendTheme, ThemeConfig } from '@chakra-ui/react';
import { AppConfig } from './config/appConfig';

const createTheme = (config: AppConfig) => {
  const themeConfig: ThemeConfig = {
    initialColorMode: 'light',
    useSystemColorMode: false,
  };

  return extendTheme({
    colors: {
      light: {
        background: config.light.backgroundColor,
        primary: config.light.primaryColor,
        secondary: config.light.secondaryColor,
        text: config.light.textColor,
        border: config.light.borderColor,
        shadow: config.light.shadowColor,
        headingText: config.light.headingTextColor,
        bodyText: config.light.bodyTextColor,
        innerBackground: 'hsl(216, 40%, 92%)', // New color for inner container background
      },
      dark: {
        background: config.dark.backgroundColor,
        primary: config.dark.primaryColor,
        secondary: config.dark.secondaryColor,
        text: config.dark.textColor,
        border: config.dark.borderColor,
        shadow: config.dark.shadowColor,
        headingText: config.dark.headingTextColor,
        bodyText: config.dark.bodyTextColor,
        innerBackground: 'hsl(210, 25%, 20%)', // New color for inner container background
      },
    },
    fonts: {
      heading: config.headingFont,
      body: config.bodyFont,
      logo: config.logoFont,
    },
    space: {
      xs: '0.5rem',
      sm: '1rem',
      md: '1.5rem',
      lg: '2rem',
      xl: '3rem',
    },
    sizes: {
      container: {
        sm: '600px',
        md: '900px',
        lg: '1600px',
      },
    },
    breakpoints: {
      sm: '600px',
      md: '900px',
      lg: '1600px',
    },
    radii: {
      sm: '3px',
      md: '6px',
      lg: '9px',
    },
    shadows: {
      sm: '0 1px 2px hsla(0, 0%, 0%, 0.05)',
      md: '0 4px 10px hsla(0, 0%, 0%, 0.1)',
      lightContainer: '0 4px 6px hsla(0, 0%, 0%, 0.2)',
      darkContainer: '0 4px 6px hsla(0, 0%, 0%, 0.6)',
    },
    config: themeConfig,
    components: {
      Button: {
        baseStyle: {
          fontWeight: 'bold',
          borderRadius: 'md',
        },
        variants: {
          solid: (props: { colorMode: string }) => ({
            bg: props.colorMode === 'dark' ? 'dark.primary' : 'light.primary',
            color: props.colorMode === 'dark' ? 'dark.text' : 'light.text',
            _hover: {
              bg: props.colorMode === 'dark' ? 'dark.secondary' : 'light.secondary',
            },
          }),
        },
      },
    },
    styles: {
      global: (props: { colorMode: string }) => ({
        body: {
          bg: props.colorMode === 'dark' ? 'dark.background' : 'light.background',
          color: props.colorMode === 'dark' ? 'dark.text' : 'light.text',
        },
      }),
    },
  });
};

export default createTheme;

import React from 'react';
import { Box, Progress, Text } from '@chakra-ui/react';
import styles from './CustomProgressBar.module.scss';

interface CustomProgressBarProps {
  progress: number;
  indeterminate?: boolean;
  progressText?: string;
  backgroundColor?: string;
  barColor?: string;
  textColor?: string;
}

const CustomProgressBar: React.FC<CustomProgressBarProps> = ({
  progress,
  indeterminate = false,
  progressText,
  backgroundColor = 'hsla(209, 70%, 13%, 0.6)',
  barColor = 'hsl(202, 100%, 40%)',
  textColor = '#FFFFFF',
}) => {
  return (
    <Box className={styles.progressBar} bg={backgroundColor}>
      <Progress
        value={indeterminate ? undefined : progress}
        size="lg"
        width="100%"
        colorScheme="blue"
        bg={backgroundColor}
        sx={{
          '& > div': {
            backgroundColor: barColor,
          },
        }}
        isIndeterminate={indeterminate}
      />
      {progressText && !indeterminate && (
        <Text
          className={styles.progressText}
          color={textColor}
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
        >
          {progressText}
        </Text>
      )}
    </Box>
  );
};

export default CustomProgressBar;

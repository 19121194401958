import { useMemo } from 'react';
import { useConfigStore } from '../store/appConfigStore';
import { createConfig, AppConfig } from '../config/appConfig';

export const useConfig = (): AppConfig => {
  const { config: storeConfig } = useConfigStore();

  return useMemo(() => {
    const defaultConfig = createConfig();
    return { ...defaultConfig, ...storeConfig };
  }, [storeConfig]);
};

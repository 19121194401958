import React, { useCallback, useMemo, useRef } from 'react';
import { Box, Text, Spinner, Tooltip } from '@chakra-ui/react';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { MdAdd } from 'react-icons/md';
import { FaRss } from 'react-icons/fa';
import styles from './TaskList.module.scss';

interface Task {
  id: string;
  name: string;
  status: string;
  created_at: string;
  updated_at: string;
  articles_generated: boolean;
  is_auto_created?: boolean;
}

interface TaskListProps {
  tasks: Task[];
  onTaskSelect: (taskId: string) => void;
  selectedTaskId: string | null;
  onNewAudioClick: () => void;
  isTaskCreationActive: boolean;
  isLoading: boolean;
}

const TaskList: React.FC<TaskListProps> = React.memo(({
  tasks,
  onTaskSelect,
  selectedTaskId,
  onNewAudioClick,
  isTaskCreationActive,
  isLoading,
}) => {
  const listOuterRef = useRef<HTMLDivElement>(null);

  const formatDate = useCallback((dateString: string) => {
    const date = new Date(dateString);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    let formattedDate = '';
    if (date.toDateString() === today.toDateString()) {
      formattedDate = 'Hoy';
    } else if (date.toDateString() === yesterday.toDateString()) {
      formattedDate = 'Ayer';
    } else {
      formattedDate = date.toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' });
    }

    const time = date.toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit' });
    return `${formattedDate} a las ${time}`;
  }, []);

  const getStatusText = useCallback((task: Task) => {
    if (task.articles_generated) {
      return 'Artículos generados';
    }
    switch (task.status.toLowerCase()) {
      case 'completed':
        return 'Completado';
      case 'pending':
        return 'Pendiente';
      case 'error':
        return 'Error';
      default:
        return task.status;
    }
  }, []);

  const handleTaskSelect = useCallback((taskId: string) => {
    const currentScrollTop = listOuterRef.current?.scrollTop || 0;
    onTaskSelect(taskId);
    requestAnimationFrame(() => {
      if (listOuterRef.current) {
        listOuterRef.current.scrollTop = currentScrollTop;
      }
    });
  }, [onTaskSelect]);

  const renderTaskItem = useCallback(({ index, style }: { index: number; style: React.CSSProperties }) => {
    const task = tasks[index];
    const isSelected = task.id === selectedTaskId;
    const isPending = task.status.toLowerCase() === 'pending';
    const isError = task.status.toLowerCase() === 'error';

    return (
      <div
        className={`${styles.taskItem} ${isSelected ? styles.selectedTaskItem : ''} ${isPending ? styles.pendingTaskItem : ''} ${isError ? styles.errorTaskItem : ''}`}
        style={style}
        onClick={() => handleTaskSelect(task.id)}
        role="button"
        tabIndex={0}
        aria-selected={isSelected}
      >
        <Text className={styles.taskName}>{task.name}</Text>
        <Text className={`${styles.taskStatus} ${task.articles_generated ? styles.generated : ''}`}>
          {getStatusText(task)}
        </Text>
        <Text className={styles.taskDate}>{formatDate(task.updated_at)}</Text>
        {task.is_auto_created && (
          <Tooltip label="Procesado automáticamente a partir de RSS" placement="top">
            <Box className={styles.rssIndicator}>
              <FaRss color="#FF6600" />
            </Box>
          </Tooltip>
        )}
      </div>
    );
  }, [tasks, selectedTaskId, handleTaskSelect, getStatusText, formatDate]);

  const itemKey = useCallback((index: number, data: Task[]) => data[index].id, []);

  const ListComponent = useMemo(() => ({ height, width }: { height: number; width: number }) => (
    <List
      height={height}
      itemCount={tasks.length}
      itemSize={80}
      width={width}
      itemKey={itemKey}
      itemData={tasks}
      overscanCount={5}
      outerRef={listOuterRef}
    >
      {renderTaskItem}
    </List>
  ), [tasks, itemKey, renderTaskItem]);

  return (
    <Box className={styles.taskList}>
      <div className={styles.newAudioButtonContainer}>
        <button
          onClick={onNewAudioClick}
          className={`${styles.newAudioButton} ${isTaskCreationActive ? styles.active : ''}`}
          aria-label="Procesar nuevo audio"
        >
          <MdAdd className={styles.buttonIcon} />
          <span className={styles.buttonText}>Procesar nuevo audio</span>
        </button>
      </div>
      <Box className={styles.listContainer}>
        {isLoading && tasks.length === 0 ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <Spinner size="xl" />
          </Box>
        ) : tasks.length > 0 ? (
          <AutoSizer>
            {({ height, width }) => (
              <ListComponent height={height} width={width} />
            )}
          </AutoSizer>
        ) : (
          <Text className={styles.noTasks}>No hay tareas disponibles</Text>
        )}
      </Box>
    </Box>
  );
});

export default TaskList;

import React from 'react';
import { Box, Heading, Text, Button, VStack } from '@chakra-ui/react';
import { FallbackProps } from 'react-error-boundary';

const ErrorFallback: React.FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  return (
    <Box
      width="100vw"
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      bg="gray.50"
    >
      <VStack spacing={4} align="center" maxWidth="400px" textAlign="center">
        <Heading as="h2" size="xl" color="red.500">
          Algo salió mal
        </Heading>
        <Text>Lo sentimos, ha ocurrido un error inesperado.</Text>
        <Text fontSize="sm" color="gray.600">
          Error: {error.message}
        </Text>
        <Button
          colorScheme="blue"
          onClick={resetErrorBoundary}
        >
          Intentar de nuevo
        </Button>
      </VStack>
    </Box>
  );
};

export default ErrorFallback;

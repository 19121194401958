import React from 'react';
import { Box, VStack, Text, Image } from '@chakra-ui/react';
import CustomProgressBar from './CustomProgressBar';
import loadingSvgUrl from '../../assets/svg/puff.svg';

interface LoadingScreenProps {
  loading?: string;
  showProgressBar?: boolean;
  progressBarType?: 'indeterminate' | 'determinate';
  progress?: number;
  progressText?: string;
}

const LoadingScreen: React.FC<LoadingScreenProps> = ({
  loading = 'Loading...',
  showProgressBar = false,
  progressBarType = 'indeterminate',
  progress = 0,
  progressText,
}) => {
  return (
    <Box
      position="fixed"
      top={0}
      left={0}
      right={0}
      bottom={0}
      display="flex"
      justifyContent="center"
      alignItems="center"
      backgroundColor="rgba(0, 0, 0, 0.7)"
      zIndex={9999}
    >
      <VStack spacing={6}>
        <Image src={loadingSvgUrl} alt="Loading" width="64px" height="64px" />
        <Text fontSize="xl" fontWeight="bold" color="white">
          {loading}
        </Text>
        {showProgressBar && (
          <Box width="200px">
            <CustomProgressBar
              indeterminate={progressBarType === 'indeterminate'}
              progress={progress}
              progressText={progressText}
            />
          </Box>
        )}
      </VStack>
    </Box>
  );
};

export default LoadingScreen;
